import { Autocomplete, CardContent, CardHeader, MenuItem, Select, TextField, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import 'react-datepicker/dist/react-datepicker.css'
import {
  GiveTerminal,
  useGiveTerminalsListQuery,
  useLazyGiveTerminalsListQuery,
  useLazyGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery
} from 'src/store/api/coreApi'

// ** Third Party Imports
import DatePicker from 'react-datepicker'

// ** Styled Components
import { Field, Formik, FormikProps } from 'formik'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'

// ** Custom Components Imports
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactApexcharts from 'src/@core/components/react-apexcharts'
import DonationStatisticsByTerminal from 'src/views/dashboard/DonationStatisticsGroup'

interface CleanedData {
  [key: string]: {
    [key: string]: any
    client_id: string
    terminal_id: string
  }
}
const MDGiveReport = () => {
  const [getFilteredChartData, result] = useLazyGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery({})
  const { data, isLoading } = useGiveTerminalsListQuery({ pageSize: 10000 })
  const [getTerminals, { data: terminalData }] = useLazyGiveTerminalsListQuery()
  const [autocompleteSearch, setAutocompleteSearch] = useState<string>('')
  const [autocompleteOptions, setAutocompleteOptions] = useState<GiveTerminal[]>([])
  const userInfo = useSelector((state: any) => state.user)
  const formikRef = useRef<any>()
  const [filteredOption, setFilteredOption] = useState(null)
  const { t } = useTranslation()

  const [isTerminalsLoading, setTerminalsLoading] = useState(false)

  const initialValues = {
    dateAfter: null,
    dateBefore: new Date(),
    terminals: [],
    reportType: 'month'
  }
  function removeOuterObject(data: any) {
    //@ts-ignore
    if (Object.values(data)[0]?.data) {
      return data
    }
    const cleanedData: CleanedData = {}
    for (const outerKey in data) {
      if (outerKey === 'total') {
        continue
      }
      for (const innerKey in data[outerKey]) {
        const innerObject = data[outerKey][innerKey]
        cleanedData[innerKey] = {
          ...innerObject,
          client_id: outerKey,
          terminal_id: innerKey
        }
      }
    }

    return cleanedData
  }

  const onSubmitForm = async (values: any) => {
    const finalData = { ...values }
    const dateAfter = new Date(finalData.dateAfter)
    finalData.dateAfter =
      dateAfter.getFullYear() +
      '-' +
      ('0' + (dateAfter.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateAfter.getDate()).slice(-2)
    const dateBefore = finalData.dateBefore ? new Date(finalData.dateBefore) : new Date()
    finalData.dateBefore = finalData.dateBefore
      ? dateBefore.getFullYear() +
        '-' +
        ('0' + (dateBefore.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dateBefore.getDate()).slice(-2)
      : undefined
    finalData.terminals = values.terminals.map((o: any) => o?.serial_number)
    finalData.client_id = values.terminals.filter((o: any) => o?.client?.id).map((o: any) => o?.client?.id)
    setFilteredOption(finalData)
    setTerminalsLoading(true)

    if (formikRef.current.values['terminals'].length > 0) {
      await getFilteredChartData(finalData)
    }
    setTerminalsLoading(false)
  }

  async function downloadCSVFile(clientId: any, key: string, type: string) {
    try {
      const response = await fetch(
        `${
          process.env.NEXT_PUBLIC_APP_API_URL
        }api/give/transactions/generate_csv_transaction/?client_id=${clientId}&date_before=${
          formikRef?.current?.values?.dateBefore?.toISOString()?.split('T')[0] || ''
        }&date_after=${formikRef?.current?.values?.dateAfter?.toISOString()?.split('T')[0] || ''}&terminals=${
          key || ''
        }${type == 'excel' ? '&file_type=excel' : ''}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        }
      )
      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `transactions${type == 'excel' ? '.xlsx' : '.csv'}`
        a.click()
        window.URL.revokeObjectURL(url)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.client && data && data?.count > 0 && data?.count < 12) {
      getFilteredChartData({
        terminals: data.results.map(ter => ter.serial_number).join(','),
        reportType: 'month',
        client_id: userInfo.client.id
      })

      formikRef.current.setFieldValue('terminals', data.results)
    }
  }, [userInfo, data])
  useEffect(() => {
    if (result.data) {
      const information = removeOuterObject(result.data)
      console.log(information, 'information')
    }
  }, [result])

  // useEffect(() => {
  //   if (autocompleteSearch.length > 0) {
  //     getTerminals({ serialNumberIcontains: autocompleteSearch })
  //   }
  // }, [autocompleteSearch])
  useEffect(() => {
    const handler = setTimeout(() => {
      // if (autocompleteSearch.length > 0) {
      // getTerminals({ serialNumberIcontains: autocompleteSearch, pageSize: 10000 });
      // }
      getTerminals({ serialNumber: autocompleteSearch, nameContains: autocompleteSearch, pageSize: 10000 })
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [autocompleteSearch])

  useEffect(() => {
    if ((terminalData?.count as number) > 0) {
      setAutocompleteOptions(terminalData?.results as GiveTerminal[])
      return
    }
    setAutocompleteOptions([])
  }, [terminalData])

  useEffect(() => {
    if ((data?.count as number) > 0) {
      setAutocompleteOptions(data?.results as GiveTerminal[])
    }
  }, [data])

  const [totalTerminalData, setTotalTerminalData] = useState({})

  // useEffect(() => {
  //   if(result?.data?.total){
  //     setTotalTerminalData(result?.data?.total)
  //   }
  // }, [result])
  useEffect(() => {
    if (result?.data && 'total' in result.data) {
      const typedData = result.data as { total: { data: any[] } } // Assert the type
      if (typedData.total?.data) {
        setTotalTerminalData(typedData.total)
      }
      return
    }
    setTotalTerminalData({})
  }, [result])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Formik initialValues={initialValues} onSubmit={onSubmitForm} innerRef={formikRef}>
          {({ values, setFieldValue, submitForm }: FormikProps<any>) => (
            <Card>
              <CardContent sx={{ textAlign: 'center' }}>
                <Grid container spacing={5}>
                  <Grid item md={6} xs={12}>
                    {!isLoading && (
                      <Field
                        as={Autocomplete}
                        name='terminals'
                        multiple
                        options={autocompleteOptions}
                        onInputChange={(event: any, newInputValue: any) => {
                          setAutocompleteSearch(newInputValue)
                        }}
                        onChange={(event: any, newValue: any | null) => {
                          setFieldValue('terminals', newValue)
                          if (newValue.length > 0) {
                            submitForm()
                          }
                        }}
                        getOptionLabel={(option: any) => `${option.name}(${option.serial_number})`}
                        renderInput={(params: any) => (
                          <TextField {...params} variant='outlined' label={t('Terminal')} />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <DatePickerWrapper>
                      <Field
                        name='dateAfter'
                        as={DatePicker}
                        selected={values.dateAfter}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat='dd-MM-yyyy'
                        selectsStart
                        endDate={values.dateBefore}
                        maxDate={values.dateBefore || new Date()}
                        onChange={(newValue: any | null) => {
                          setFieldValue('dateAfter', newValue)
                          submitForm()
                        }}
                        customInput={<TextField label={t('FromDate')} fullWidth disabled />}
                      />
                    </DatePickerWrapper>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <DatePickerWrapper>
                      <Field
                        as={DatePicker}
                        selected={values.dateBefore}
                        name='dateBefore'
                        dateFormat='dd-MM-yyyy'
                        showYearDropdown
                        showMonthDropdown
                        selectsEnd
                        startDate={values.dateAfter}
                        endDate={values.dateBefore}
                        minDate={values.dateAfter}
                        maxDate={new Date()}
                        placeholderText='DD-MM-YYYY'
                        customInput={<TextField label={t('UntilDate')} fullWidth />}
                        onChange={(newValue: any | null) => {
                          setFieldValue('dateBefore', newValue)
                          submitForm()
                        }}
                      />
                    </DatePickerWrapper>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Field
                      as={Select}
                      name='reportType'
                      label={t('Type')}
                      fullWidth
                      onChange={(e: any) => {
                        setFieldValue('reportType', e.target.value)
                        submitForm()
                      }}
                    >
                      <MenuItem value={'day'}>{t('PerDay')}</MenuItem>
                      <MenuItem value={'week'}>{t('PerWeek')}</MenuItem>
                      <MenuItem value={'month'}>{t('PerMonth')}</MenuItem>
                      <MenuItem value={'year'}>{t('PerYear')}</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </Grid>

      {(totalTerminalData as { data: any[] })?.data?.length && !isTerminalsLoading && (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={'Summary'} titleTypographyProps={{ variant: 'h5' }} />
            <DonationStatisticsByTerminal
              filters={filteredOption}
              serialNumber={Object.keys(removeOuterObject(result.data))
                .filter(key => key !== 'total')
                .join(',')}
              client_id={userInfo?.client?.id || ''}
            />
            <ReactApexcharts
              type='line'
              height={300}
              options={{
                chart: {
                  height: 350,
                  toolbar: {
                    tools: {
                      download: false,
                      customIcons: [
                        {
                          icon: `<img src="/images/csvdownload.png" style="margin-left:6px" width="20">`,
                          index: 4,
                          title: 'Download CSV File',
                          click: function () {
                            downloadCSVFile(
                              userInfo?.is_superuser ? '' : userInfo?.client?.id,
                              Object.keys(removeOuterObject(result.data))
                                .filter(key => key !== 'total')
                                .join(','),
                              ''
                            )
                          }
                        },
                        {
                          icon: `<img src="/images/exceldownload.png" style="margin-left:6px" width="20">`,
                          index: 4,
                          title: 'Download Excel File',
                          click: function () {
                            downloadCSVFile(
                              userInfo?.is_superuser ? '' : userInfo?.client?.id,
                              Object.keys(removeOuterObject(result.data))
                                .filter(key => key !== 'total')
                                .join(','),
                              'excel'
                            )
                          }
                        }
                      ]
                    }
                  },
                  animations: {
                    enabled: false
                  }
                },
                xaxis: {
                  type: 'category'
                },
                stroke: {
                  width: [0, 2],
                  colors: ['#40A0FC']
                },

                yaxis: [
                  {
                    title: {
                      text: t('TotalDonation')
                    },
                    labels: {
                      formatter: function (val: any) {
                        return Math.floor(val).toString()
                      }
                    }
                  },
                  {
                    title: {
                      text: t('AverageDonation') + ' (€)'
                    },
                    opposite: true,
                    labels: {
                      formatter: function (val: any) {
                        return val.toFixed(2)
                      }
                    }
                  }
                ],
                plotOptions: {
                  bar: {
                    columnWidth: '30%',
                    startingShape: 'rounded',
                    endingShape: 'flat',
                    borderRadius: 1
                  }
                },
                colors: ['#e81a66']
              }}
              series={(totalTerminalData as any)?.data}
            />
          </Card>
        </Grid>
      )}

      {result?.data &&
      formikRef?.current?.values?.terminals?.length > 0 &&
      Object.entries(removeOuterObject(result.data)).length > 0 &&
      !isTerminalsLoading ? (
        Object.entries(removeOuterObject(result.data)).map(([key, value], idx) => (
          <Grid item xs={12} key={`${key}-${idx}`}>
            <Card>
              <CardHeader title={(value as any)?.name} titleTypographyProps={{ variant: 'h5' }} />
              <DonationStatisticsByTerminal
                filters={filteredOption}
                serialNumber={(value as any)?.terminal_id}
                client_id={(value as any)?.client_id}
              />
              <ReactApexcharts
                type='line'
                height={300}
                options={{
                  chart: {
                    height: 350,
                    toolbar: {
                      tools: {
                        download: false,
                        customIcons: [
                          {
                            icon: `<img src="/images/csvdownload.png" style="margin-left:6px" width="20">`,
                            index: 4,
                            title: 'Download CSV File',
                            click: function () {
                              downloadCSVFile(
                                userInfo?.is_viewer ? userInfo?.client?.id : (value as any)?.client_id,
                                (value as any)?.terminal_id,
                                ''
                              )
                            }
                          },
                          {
                            icon: `<img src="/images/exceldownload.png" style="margin-left:6px" width="20">`,
                            index: 4,
                            title: 'Download Excel File',
                            click: function () {
                              downloadCSVFile(
                                userInfo?.is_viewer ? userInfo?.client?.id : (value as any)?.client_id,
                                (value as any)?.terminal_id,
                                'excel'
                              )
                            }
                          }
                        ]
                      }
                    },
                    animations: {
                      enabled: false
                    }
                  },
                  xaxis: {
                    type: 'category'
                  },
                  stroke: {
                    width: [0, 2]
                  },

                  yaxis: [
                    {
                      title: {
                        text: t('TotalDonation')
                      },
                      labels: {
                        formatter: function (val: any) {
                          return Math.floor(val).toString()
                        }
                      }
                    },
                    {
                      title: {
                        text: t('AverageDonation') + ' (€)'
                      },
                      opposite: true,
                      labels: {
                        formatter: function (val: any) {
                          return val.toFixed(2)
                        }
                      }
                    }
                  ],
                  plotOptions: {
                    bar: {
                      columnWidth: '20%',

                      startingShape: 'rounded',
                      endingShape: 'flat',
                      borderRadius: 1
                    }
                  }
                }}
                series={(value as any)?.data}
              />
            </Card>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography textAlign={'center'}>
            {isTerminalsLoading ? 'Fetching records...' : 'No records found.'}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export async function getStaticProps(context: any) {
  // extract the locale identifier from the URL
  const { locale } = context

  return {
    props: {
      // pass the translation props to the page component
      ...(await serverSideTranslations(locale))
    }
  }
}

export default MDGiveReport
